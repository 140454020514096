import { createApp } from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import i18n from './i18u'
import myUser from './myUser'

import axios from 'axios';

// Vuetify
import 'vuetify/styles'
import { createVuetify } from 'vuetify'
import * as components from 'vuetify/components'
import * as directives from 'vuetify/directives'
const vuetify = createVuetify({
    components,
    directives,
})


// import VueGoogleMaps from '@fawmi/vue-google-maps';

// axios.defaults.headers.common['Access-Control-Allow-Origin'] = '*';
// axios.defaults.headers.common['Access-Control-Allow-Methods'] = 'POST, PATCH, PUT, DELETE, OPTIONS';
// axios.defaults.headers.common['Access-Control-Allow-Headers'] = 'Access-Control-Allow-Headers, Content-Type, Authorization';
axios.defaults.headers.common['Content-type'] = 'application/json';
// axios.defaults.headers.common['Access-Control-Allow-Credentials'] = 'true';
// axios.defaults.withCredentials = true;
axios.defaults.baseURL = 'https://www.scard9.com/aaa/api_web/';

// createApp(App).use(router).use(i18n).use(vuetify).use(myUser).use(VueGoogleMaps, {
//     load: {
//       key: 'AIzaSyC0kb_pdsJBwIn2uUyQoDWmjFYUlynSeFE',
//       libraries: "places"
//     },
//   }).mount('#app')

createApp(App).use(router).use(i18n).use(vuetify).use(myUser).mount('#app')
